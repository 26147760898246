import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "isBox": true,
      "flipKey": 94
    }}><h1 parentName="div">{`Choice`}</h1>
      <div className="subtitle">Ein Forschungsprojekt zur Schulwahl an Berliner Grundschulen stellt sich vor.</div>
      <p parentName="div">{`Herzlich willkommen auf der Website des Projekts Choice. Wir freuen uns, dass Sie Interesse an unserem Projekt haben und hoffen auf den folgenden Seiten alle Fragen beantworten zu können. Für weitere Rückfragen stehen wir gern zur Verfügung.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 95
    }}>
      <h2 parentName="div">{`Wer ist Choice?`}</h2>
      <p parentName="div"><em parentName="p">{`Choice. Schulwahl und sozio-kulturelle Passung`}</em>{` ist ein Forschungsprojekt in Berlin. Es wird von Bildungsforscherinnen und Bildungsforschern der `}<a parentName="p" {...{
          "href": "https://www.hu-berlin.de/"
        }}>{`Humboldt-Universität zu Berlin`}</a>{` und der `}<a parentName="p" {...{
          "href": "http://www.uni-muenster.de/de/"
        }}>{`Westfälischen Wilhelms-Universität Münster`}</a>{` durchgeführt.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 96
    }}>
      <h2 parentName="div">{`Was untersucht Choice?`}</h2>
      <p parentName="div">{`Die Untersuchung geht zum einen der Frage nach: `}<strong parentName="p">{`Warum wählt wer welche Schule für sein/ihr Kind?`}</strong>{` Das Projekt erfasst also die Schulwahlmotive von Eltern vor dem Hintergrund der von ihnen wahrgenommenen Merkmale von Schulqualität (z.B. Schulklima, Unterrichtsmethoden, Klassen- und Schulgröße und Engagement der Lehrpersonen) sowie anderer schulwahlrelevanter Aspekte (z. B. Länge des Schulwegs). Dies schließt selbstverständlich die Frage ein: `}<strong parentName="p">{`Wer wählt nicht und warum?`}</strong></p>
      <p parentName="div">{`Zum anderen wird der Frage nachgegangen, welche Rolle Schulen und Schulleitungen im Prozess der Schulwahl spielen, also: `}<strong parentName="p">{`Wie wählen Schulen ihre Schülerinnen und Schüler aus?`}</strong><br parentName="p"></br>{`
`}{`Der erste Teil der Studie (2014-2018) fokussiert den Übergang von der Kita in die Grundschule. Seit 2020 untersuchen wir nun in einem zweiten Teil auch den Übergang von der Grundschule in die weiterführende Schule.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 97
    }}>
      <h2 parentName="div">{`Was ist das Ziel der Studie?`}</h2>
      <p parentName="div">{`Ziel der Untersuchung ist es, die Passung zwischen elterlichen – auf die Schule bezogenen – Erziehungs- und Bildungsvorstellungen und den unterschiedlichen Schultypen bzw. Schulprofilen mit ihren mannigfaltigen (schul-)pädagogischen, religiösen oder sozialen Orientierungen und Angeboten aufzuzeigen, und die Effekte dieser Passung für die Schulentwicklung der Einzelschule und das Schulsystem im Ganzen zu diskutieren.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 98
    }}>
      <h2 parentName="div">{`Wie wird die Studie durchgeführt?`}</h2>
      <p parentName="div">{`Im Rahmen der repräsentativen Studie wurden Eltern und Schulleitungen in ganz Berlin sowohl schriftlich als auch mündlich befragt. Die Befragungen zum ersten Teil des Forschungsprojekts fanden an insgesamt 200 öffentlichen und privaten/freien Grundschulen in den Jahren 2015 und 2016 statt. Die gleichen Eltern werden von uns jetzt im Schuljahr 2020/21 nochmals befragt. Dabei steht die Übergangsentscheidung in die weiterführende Schule im Fokus. Sowohl die Fragebogenerhebung als auch die Interviews erfolgen anonym und online bzw. durch die Projektmitarbeiterinnen. `}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 99
    }}>
      <h2 parentName="div">{`Was ist neu an dieser Studie?`}</h2>
      <p parentName="div">{`Die Studie ist die `}<strong parentName="p">{`erste wissenschaftliche Untersuchung`}</strong>{`, die die elterlichen Schulwahlmotive vergleichend in den Blick nimmt und Gemeinsamkeiten und Unterschiede elterlicher Schulwahlmotivation sowie Schülerinnen- und Schülerauswahlprozesse in beiden Segmenten (privat & öffentlich) des Grundschulwesens offenlegt. Mit dem zweiten Teil der Studie ist es möglich, Unterschiede und Gemeinsamkeiten zwischen den beiden Bildungsübergängen herauszuarbeiten und vergleichend zu analysieren. `}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 100
    }}>
      <h2 parentName="div">{`Was passiert mit meinen Daten?`}</h2>
      <p parentName="div">{`Die Umfrage ist freiwillig und Sie bleiben bei Ihrer Teilnahme an der Befragung vollkommen anonym. Alle Angaben werden streng vertraulich behandelt, nur für wissenschaftliche Zwecke ausgewertet, nicht an Dritte weitergegeben. Damit erfolgt die Datenerhebung in voller Übereinstimmung mit der Datenschutzgesetzgebung Berlins, den Richtlinien der Fachgesellschaften Erziehungswissenschaft und der Deutschen Forschungsgemeinschaft (DFG).`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 101
    }}>
      <h2 parentName="div">{`Noch mehr Fragen?`}</h2>
      <p parentName="div">{`Wenn Sie weitere Fragen haben, können Sie uns jederzeit `}<a parentName="p" {...{
          "href": "/contact/"
        }}>{`kontaktieren`}</a>{`!`}</p>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      